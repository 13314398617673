<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">优惠券详情</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="w-100 p-4 m-0" style="font-size: 14px;" v-if="coupon != null">
				<el-divider><span style="font-weight: 800;">基本信息</span></el-divider>
				<p>劵类型：<el-tag size="small">{{coupon.coupon_type.text}}</el-tag></p>
				<p>劵名称：{{coupon.name}}</p>
				<p>使用条件：{{coupon.condition}}</p>
				<p>使用须知：{{coupon.intro}}</p>

				<el-divider><span style="font-weight: 800;">劵信息</span></el-divider>
				<p>当前库存数量：<span style="color: red;font-weight: 800;">{{coupon.stock_num}}</span> 张</p>
				<p>用户限领张数：<span style="color: red;font-weight: 800;">{{coupon.limit_num}}</span> 张</p>
				<p v-if="coupon.expire_type == 10">
					有效期：{{coupon.start_time}} - {{coupon.end_time}}
				</p>
				<p v-if="coupon.expire_type == 20">
					有效期：领取后 <span style="color: red;font-weight: 800;">{{coupon.start_day}}</span> 天内有效
				</p>
				<p v-if="coupon.expire_type == 30">
					有效期：领取后 <span style="color: red;font-weight: 800;">{{coupon.start_day}}</span> 天- <span
						style="color: red;font-weight: 800;">{{coupon.end_day}}</span> 天内有效
				</p>
				<p v-if="coupon.is_limit_time == 20 && coupon.expire_type != 10">
					领取截止日期：{{coupon.limit_time}}
				</p>
				<p>
					可用时段（日期）： <el-tag size="small" v-if="coupon.hour.week.indexOf(10) > -1">周一</el-tag>
					<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(20) > -1">周二</el-tag>
					<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(30) > -1">周三</el-tag>
					<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(40) > -1">周四</el-tag>
					<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(50) > -1">周五</el-tag>
					<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(60) > -1">周六</el-tag>
					<el-tag size="small" class="ml-1" v-if="coupon.hour.week.indexOf(70) > -1">周日</el-tag>
				</p>
				<p>
					可用时段（时间）： {{coupon.hour.time.start}} - {{coupon.hour.time.end}}
				</p>

				<el-divider><span style="font-weight: 800;">活动规则</span></el-divider>
				<p>
					限制领取地区：<span v-if="coupon.rule.region_limit == 20">{{coupon.rule.province_name}}
						{{coupon.rule.city_name}} {{coupon.rule.region_name}}</span><span v-else>不限制</span>
				</p>
				<p>核销后赠送：<span v-if="coupon.rule.clerk_give == 20">开启</span><span v-else>关闭</span></p>
				<p>禁止赠送：<span v-if="coupon.rule.forbid_give == 20">开启</span><span v-else>关闭</span></p>
				<p>禁止分享：<span v-if="coupon.rule.forbid_share == 20">开启</span><span v-else>关闭</span></p>
				<p>分享更优惠（裂变卷）：<span v-if="coupon.rule.share_minus == 20">开启</span><span v-else>关闭</span></p>
				<p>用户信息收集：<span v-if="coupon.rule.is_user_info == 20">开启</span><span v-else>关闭</span></p>

				<el-divider><span style="font-weight: 800;">商家详情信息</span></el-divider>
				<p>商家名称：{{coupon.couponMerchant.merchant_name}}</p>
				<p class="d-flex align-items-center">LOGO：<viewer><img class="ml-3" style="width: 45px;height: 45px;"
							:src="coupon.couponMerchant.logo"></viewer>
				</p>
				<p>商家电话：{{coupon.couponMerchant.mobile}}</p>
				<p>微信号：{{coupon.couponMerchant.wechat}}</p>
				<p>商家地址：{{coupon.couponMerchant.address}}</p>
				<p>详细地址：{{coupon.couponMerchant.address_detail}}</p>
				<el-divider><span style="font-weight: 800;">图文详情</span></el-divider>
				<template v-for="(item,index) in coupon.couponImageText">
					<template v-if="item.type == 10">
						<p>{{item.guidance}}：{{item.content}}</p>
					</template>
					<template v-else>
						<p class="d-flex align-items-center">
							<viewer><img class="mr-2" style="width: 45px;height: 45px;" :src="item.image"></viewer>
							<span>{{item.content}}</span>
						</p>
					</template>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				coupon: null,
				couponId: this.$route.params.coupon_id,
			}
		},
		watch: {
			$route() {
				this.couponId = this.$route.params.coupon_id,
				this.getCouponDetail()
			},
		},
		created() {
			this.getCouponDetail()
		},
		methods: {
			getCouponDetail() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/coupon/detail',
						coupon_id: this.couponId
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.coupon = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			}
		},
	}
</script>

<style>

</style>
